.mainGame {
    position: relative;
    height: 100%;
    width: 100%;
}

.version-number {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    color: black;
    z-index: 10;
}

.html-container {
    pointer-events: none;
}
