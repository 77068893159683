.main-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vw;
    overflow: hidden;
    font-size: 1.5vh;
}

.left-panel {
    width: 25vw;
    height: 100vh;
    left: 0;
    position: relative;
    background-color: grey;
    border-right: .3vh solid black;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bottom-panel {
    width: 100vw;
    height: 40vh;
    position: relative;
    background-color: grey;
    border-top: .3vh solid black;
}

.main-game {
    flex-grow: 1;
    height: 100vh;
    overflow: hidden;
    background-color: blue;
}

.main-game-container {
    display: flex;
    flex-direction: column;
    width: 75vw;
    height: 100vh;
}